import {
  createContext,
  Dispatch,
  ReactNode,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ILayer,
  ISelectionMask,
  IMaskMode,
  ISegmentMask,
  IShapesSchema,
} from '../../../schemas/retouch.schema';
import { Layer } from 'konva/lib/Layer';
import Konva from 'konva';
import {
  AreaTransferToolConfiguration,
  GenerationParams,
  GenerationResponse,
  IGeneration,
  PaletteToolConfiguration,
  RemixToolConfiguration,
  RetouchGenerationResponse,
  SilhouetteToolConfiguration,
  UpscalerToolConfiguration,
} from '../types/Generation';
import { GenerationStatus } from '../types/GenerationStatus';
import { GenerationTools } from '../../../utils/graphql-operations';
import { studioApi } from '../../../services/studioApi';
import {
  CanvasSize,
  CanvasTool,
  emptyLayer,
  emptyShapes,
} from '../data/retouch';
import { useOutletContext } from 'react-router-dom';
import { IOutletContextProps } from '../../../layout/MainContentContainer';
import {
  GENERATION_FINISHED,
  GENERATION_TAB_FINISHED,
} from '../../../utils/feedbackMessages';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { ASPECT_RATIO } from '../../../utils';
import { analytics } from '../../../libs/Segment';
import { getImageSize } from '../../../libs/imageUtils';
import { AIEffectsOptions } from '../data/effects';
import { MenuValue } from '../components/RightPanel/components/MenuSelector';
import { applyMaskAndGetMaskBase64 } from '../lib/masks';
import { useAuth } from '@workos-inc/authkit-react';

interface HandleGenerateParams {
  tool: GenerationTools;
  prompt: string;
  negativePrompt?: string;
  image?: string;
  mask?: string;
  toolParameters?:
    | PaletteToolConfiguration
    | RemixToolConfiguration
    | SilhouetteToolConfiguration
    | AreaTransferToolConfiguration
    | UpscalerToolConfiguration;
  parentLayers: string[];
  cutWithMask?: boolean;
}

function enhanceStateSetter<T>(
  arg: T | ((prevState: T) => T),
  setter: Dispatch<SetStateAction<T>>,
): void {
  if (typeof arg === 'function') {
    setter((prevState) => {
      const updater = arg as (prevState: T) => T;
      return updater(prevState);
    });
  } else {
    setter(arg);
  }
}

type Updater<T> = (prevState: T) => T;

interface RetouchContextProps {
  loading: boolean;
  handleChangeLoading(loading: boolean): void;

  containerRef: RefObject<HTMLDivElement>;

  generationTool: GenerationTools | null;
  handleChangeGenerationTool(tool: GenerationTools | null): void;

  isGenerating: boolean;
  isGenerationFailed?: boolean;

  stageRef: RefObject<Konva.Stage>;

  layers: ILayer[];
  layersRef: RefObject<Record<string, Konva.Layer>>;
  handleChangeLayers(layers: ILayer[]): void;

  currentLayerId: string | null;
  handleChangeCurrentLayerId(layerId: string | null): void;
  handleCreateLayer(): void;

  shapes: IShapesSchema;
  segmentMask: ISegmentMask | null;
  clearAllShapes(): void;

  handleChangeShapes(arg: IShapesSchema | Updater<IShapesSchema>): void;
  handleChangeSegmentMask(mask: ISegmentMask | null): void;
  handleGenerate(params: HandleGenerateParams): Promise<void>;

  canvasSize: {
    width: number;
    height: number;
  };
  handleChangeCanvasSize(params: { width: number; height: number }): void;

  selectionMask: ISelectionMask | null;
  handleChangeSelectionMask(mask: ISelectionMask | null): void;

  maskMode: IMaskMode | null;
  setMaskMode: Dispatch<SetStateAction<IMaskMode | null>>;

  tool: CanvasTool;
  handleChangeTool(tool: CanvasTool): void;

  isLoadingCache: boolean;
  handleChangeIsLoadingCache(loading: boolean): void;

  eraserActive: boolean;
  handleChangeEraserActive(active: boolean): void;

  popoverOpen: boolean;
  handleChangePopoverOpen(open: boolean): void;

  selectedAIEffect: AIEffectsOptions | null;
  handleChangeSelectedAIEffect(effect: AIEffectsOptions | null): void;

  selectedMenuItem: MenuValue;
  handleChangeSelectedMenuItem(item: MenuValue): void;
}

export const RetouchContext = createContext<RetouchContextProps>(
  {} as RetouchContextProps,
);

const initialLayer = emptyLayer([]);

export function RetouchProvider({ children }: { children: ReactNode }) {
  const stageRef = useRef<Konva.Stage>(null);
  const layersRef = useRef<Record<string, Layer>>({});
  const [layers, setLayers] = useState<ILayer[]>([initialLayer]);
  const [generationTool, setGenerationTool] = useState<GenerationTools | null>(
    null,
  );
  const { getAccessToken } = useAuth();
  const [currentLayerId, setCurrentLayerId] = useState<string | null>(
    initialLayer.id,
  );
  const currentLayerIdOfGeneration = useRef<string | null>(null);
  const generationStartTime = useRef<number | null>(null);

  const [shapes, setShapes] = useState<IShapesSchema>(emptyShapes);
  const [segmentMask, setSegmentMask] = useState<ISegmentMask | null>(null);
  const [selectionMask, setSelectionMask] = useState<ISelectionMask | null>(
    null,
  );
  const [maskMode, setMaskMode] = useState<IMaskMode | null>(null);
  const [tool, setTool] = useState(CanvasTool.Select);

  const [isLoadingCache, setIsLoadingCache] = useState(true);
  const [eraserActive, setEraserActive] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const { sendNotificaton, changeTabName } =
    useOutletContext<IOutletContextProps>();

  const errorStatuses = [
    GenerationStatus.FAIL,
    GenerationStatus.PARTIAL_FAIL,
    GenerationStatus.ERROR,
  ];
  const currentLayer = layers.find((layer) => layer.id === currentLayerId);

  const currentGeneration =
    currentLayer?.generations[currentLayer.generations.length - 1];

  const isGenerationFailed =
    currentGeneration && errorStatuses.includes(currentGeneration.status);

  const isGenerating = !!generationTool && !isGenerationFailed;

  const [canvasSize, setCanvasSize] = useState({
    width: 0,
    height: 0,
  });

  const [selectedAIEffect, setSelectedAIEffect] =
    useState<AIEffectsOptions | null>(null);

  const [selectedMenuItem, setSelectedMenuItem] =
    useState<MenuValue>('gallery');

  const clearAllShapes = useCallback(() => {
    setShapes(emptyShapes);
    setSegmentMask(null);
    setSelectionMask(null);
    setEraserActive(false);
  }, []);

  useEffect(() => {
    if (maskMode) {
      analytics.track('mask mode selected');
    }
  }, [maskMode]);

  function handleChangeIsLoadingCache(loading: boolean) {
    setIsLoadingCache(loading);
  }

  function handleChangeEraserActive(active: boolean) {
    setEraserActive(active);
  }

  function handleChangeTool(tool: CanvasTool) {
    setTool(tool);
  }

  function handleChangeLoading(loading: boolean) {
    setLoading(loading);
  }

  function handleChangeSelectionMask(mask: ISelectionMask | null) {
    setSelectionMask(mask);
  }

  function handleChangeCanvasSize(size: { width: number; height: number }) {
    setCanvasSize(size);
  }

  function handleChangeCurrentLayerId(layerId: string | null) {
    setCurrentLayerId(layerId);
  }

  function handleChangeGenerationTool(tool: GenerationTools | null) {
    setGenerationTool(tool);
  }

  function handleChangeLayers(layers: ILayer[]) {
    const orderedLayers = layers.map((layer, index) => ({
      ...layer,
      order: layers.length - index,
    }));

    setLayers(orderedLayers);
  }

  function handleChangeShapes(arg: IShapesSchema | Updater<IShapesSchema>) {
    enhanceStateSetter(arg, setShapes);
  }

  function handleChangeSegmentMask(mask: ISegmentMask | null) {
    setSegmentMask(mask);
  }

  function handleChangePopoverOpen(open: boolean) {
    setPopoverOpen(open);
  }

  function handleChangeSelectedAIEffect(effect: AIEffectsOptions | null) {
    setSelectedAIEffect(effect);
  }

  function handleChangeSelectedMenuItem(item: MenuValue) {
    setSelectedMenuItem(item);
  }

  function handleCreateLayer() {
    const newLayer = emptyLayer(layers);

    setCurrentLayerId(newLayer.id);
    handleChangeLayers([newLayer, ...layers]);
  }

  function trackGeneration(
    generationId: string,
    cutWithMask: boolean,
    mask?: string,
  ) {
    const subscription = new EventSource(
      `${import.meta.env.VITE_API_BASE_URL}/api/services/studio/ai/jobs/${generationId}`,
    );

    /**
     *
     * @param layerId This parameter is just for if transformation of the mask delay is more than the backend generation; we send the layer ID because the currentLayerID will be null.
     */
    const updateLayers = (
      updateFn: (generation: IGeneration) => IGeneration,
      layerId?: string,
    ) => {
      setLayers((prevState) => {
        const updatedGenerations = prevState
          .find(
            (layer) =>
              layer.id === (layerId ?? currentLayerIdOfGeneration.current),
          )
          ?.generations.map((generation) => updateFn(generation));

        return prevState.map((layer) =>
          layer.id === (layerId ?? currentLayerIdOfGeneration.current)
            ? { ...layer, generations: updatedGenerations ?? layer.generations }
            : layer,
        );
      });
    };

    const trackGenerationTime = () => {
      if (generationStartTime.current !== null) {
        const generationTime = Date.now() - generationStartTime.current;
        analytics.track('Generation Completed', {
          generationTime,
          generationTool,
        });
        generationStartTime.current = null;
      }
    };

    const handleGenerationComplete = () => {
      updateLayers((generation) => ({
        ...generation,
        status:
          generation.status === GenerationStatus.PROCESSING
            ? GenerationStatus.SUCCESS
            : GenerationStatus.FAIL,
      }));

      trackGenerationTime();
      sendNotificaton(GENERATION_FINISHED);
      changeTabName(GENERATION_TAB_FINISHED);
      setGenerationTool(null);
      currentLayerIdOfGeneration.current = null;

      subscription.close();
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleGenerationUpdate = (response: RetouchGenerationResponse) => {
      if (response.status === 'fail') {
        updateLayers(() => ({ status: GenerationStatus.FAIL }));
        Sentry.captureException(
          'Generation Fail on retouch tool: ' + generationTool,
        );
        setGenerationTool(null);
        currentLayerIdOfGeneration.current = null;
        return;
      }
      const generatedImage =
        response.child_jobs?.[response.child_jobs.length - 1]?.image ??
        response.image ??
        '';

      if (generatedImage) {
        getImageSize(generatedImage).then((imageSize) => {
          if (
            imageSize.width !== CanvasSize.Width ||
            imageSize.height !== CanvasSize.Height
          ) {
            Sentry.captureException(
              'Generation Ai Effect image size is not correct',
            );
          }
        });
      }

      const updateGenerationData = (
        data: RetouchGenerationResponse,
        imageMask?: string,
        layerId?: string,
      ) => {
        const images = data.child_jobs
          ? data.child_jobs.map((job) => job.image)
          : [data.image ?? ''];

        const newImages =
          images.length > 1 ? images[images.length - 1] : images[0];

        updateLayers(
          (generation) => ({
            ...generation,
            ...data,
            payload: {
              ...generation.payload,
              job_id:
                data.child_jobs?.[data.child_jobs.length - 1]?.child_job_id ??
                data.child_job_id ??
                '',
              images: [
                ...(generation.payload?.images ?? []),
                ...(newImages ? [newImages] : []),
              ],
            },
            ...(imageMask && { mask: imageMask }),
            status:
              data.status === 'success'
                ? GenerationStatus.SUCCESS
                : GenerationStatus.PROCESSING,
          }),
          layerId,
        );
      };

      if (cutWithMask && mask) {
        const layerId = JSON.parse(
          JSON.stringify(currentLayerIdOfGeneration.current),
        );
        const responseWithoutReference = JSON.parse(JSON.stringify(response));
        applyMaskAndGetMaskBase64({
          maskImg: mask,
          originalImg: generatedImage,
        }).then((imageMask) => {
          updateGenerationData(responseWithoutReference, imageMask, layerId);
        });
      } else {
        updateGenerationData(response);
      }
    };

    subscription.onmessage = (event) => {
      if (event.data === '[DONE]') {
        handleGenerationComplete();
      } else {
        handleGenerationUpdate(JSON.parse(event.data));
      }
    };

    subscription.onerror = (err) => {
      console.error('EventSource failed:', err);
      updateLayers(() => ({ status: GenerationStatus.FAIL }));
      Sentry.captureException(
        'EventSource failed on retouch tool: ' + generationTool,
      );
      setGenerationTool(null);
      currentLayerIdOfGeneration.current = null;
      subscription.close();
    };
  }

  async function handleGenerate({
    tool,
    prompt,
    mask,
    toolParameters,
    parentLayers,
    cutWithMask = false,
  }: HandleGenerateParams) {
    if (!currentLayerId) {
      toast.error('Please select a layer to generate');
      return;
    }

    currentLayerIdOfGeneration.current = currentLayerId;
    setGenerationTool(tool);
    generationStartTime.current = Date.now();
    setLayers((prevState) => {
      const newGeneration: IGeneration = {
        effects: {
          tool,
          parentLayers,
        },
        status: GenerationStatus.PROCESSING,
      };

      return prevState.map((layer) =>
        layer.id === currentLayerIdOfGeneration.current
          ? { ...layer, generations: [...layer.generations, newGeneration] }
          : layer,
      );
    });

    try {
      const token = await getAccessToken();
      const params: GenerationParams = {
        tool,
        prompt,
        image_amount: 3,
        image_quality: 1,
        aspect_ratio: ASPECT_RATIO.FILM,
        llm_assist: false,
        ...(toolParameters && {
          tool_configuration: {
            ...toolParameters,
            ...(mask && { input_mask: mask }),
          },
        }),
      };

      const { data: generationRequest } =
        await studioApi.post<GenerationResponse>('/ai/tools', params, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });

      trackGeneration(generationRequest.jobId, cutWithMask, mask);
    } catch (err) {
      Sentry.captureException('Error generating image, ' + generationTool, {
        extra: {
          error: (err as Error).message,
        },
      });
      setLayers((prevState) =>
        prevState.map((layer) =>
          layer.id === currentLayerIdOfGeneration.current
            ? {
                ...layer,
                generations: layer.generations.map((generation) =>
                  generation.status === GenerationStatus.PROCESSING
                    ? { ...generation, status: GenerationStatus.ERROR }
                    : generation,
                ),
              }
            : layer,
        ),
      );
      currentLayerIdOfGeneration.current = null;
    }
  }

  return (
    <RetouchContext.Provider
      value={{
        eraserActive,
        handleChangeEraserActive,

        loading,
        handleChangeLoading,

        containerRef,

        generationTool,
        handleChangeGenerationTool,

        isGenerating,
        isGenerationFailed,

        layersRef,
        layers,
        handleChangeLayers,

        currentLayerId,
        handleChangeCurrentLayerId,
        handleCreateLayer,

        shapes,
        segmentMask,
        clearAllShapes,

        handleChangeShapes,
        handleChangeSegmentMask,

        handleGenerate,

        canvasSize,
        handleChangeCanvasSize,

        selectionMask,
        handleChangeSelectionMask,

        maskMode,
        setMaskMode,

        tool,
        handleChangeTool,

        isLoadingCache,
        handleChangeIsLoadingCache,

        stageRef,

        popoverOpen,
        handleChangePopoverOpen,

        selectedAIEffect,
        handleChangeSelectedAIEffect,

        selectedMenuItem,
        handleChangeSelectedMenuItem,
      }}
    >
      {children}
    </RetouchContext.Provider>
  );
}
