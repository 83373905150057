import { Suspense, lazy } from "react";
import {
	Route,
	RouterProvider,
	createRoutesFromElements,
} from "react-router-dom";
import sentryCreateBrowserRouter from "./libs/Sentry";
import "./utils/intercom-changes.css";
import { Loading } from "./components/ui/Loading";
import { Page } from "./routes/data/route";
import ErrorPage from "./pages/Errors";
import { Outlet } from "react-router-dom";
import { useRoutes } from "./routes/routes";
import { WorkosProviderWithNavigate } from "./context/WorkosProviderWithNavigate";
const RootLayout = lazy(() => import("./pages/Root"));

function App() {
	const routes = useRoutes();

	function WorkosProviderLayout() {
		return (
			<WorkosProviderWithNavigate>
				<Outlet />
			</WorkosProviderWithNavigate>
		);
	}

	const router = sentryCreateBrowserRouter(
		createRoutesFromElements(
			<Route element={<WorkosProviderLayout />}>
				<Route
					element={<RootLayout />}
					errorElement={<ErrorPage pageType={Page.Wrong} />}
				>
					{routes.map((route) => (
						<Route key={route.path} path={route.path} element={route.element} />
					))}
				</Route>
			</Route>,
		),
	);

	return (
		<Suspense fallback={<Loading />}>
			<RouterProvider router={router} />
		</Suspense>
	);
}

export default App;
