import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import TagManager from "react-gtm-module";
import { ToastContainer } from "react-toastify";
import { PostHogProvider } from "posthog-js/react";

const flag = import.meta.env.VITE_GOOGLE_TAG_MANAGER_FLAG;
if (flag === "Enabled") {
	const tagManagerArgs = {
		gtmId: "G-30WW52GH48",
	};
	TagManager.initialize(tagManagerArgs);
}

const options = {
	api_host: import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_HOST,
};

ReactDOM.createRoot(document.getElementById("root")!).render(
	<>
		<PostHogProvider
			apiKey={import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_KEY}
			options={options}
		>
			<App />
			<ToastContainer
				position="bottom-left"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
		</PostHogProvider>
	</>,
);
