import axios from 'axios';

const baseURL = import.meta.env.VITE_API_BASE_URL + '/api/services/studio';

export const studioApi = axios.create({
  baseURL: baseURL,
  params: {
    service: 'retouch',
  },
});
