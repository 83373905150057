/* eslint-disable react-refresh/only-export-components */
import { Page } from "./data/route";
import { lazy } from "react";
import { RetouchProvider } from "../components/retouch/context/RetouchContext";
const StripePricingTable = lazy(() => import("../pages/StripePricingTable"));
const RetouchSessionPage = lazy(() => import("../pages/RetouchSessions"));
const RetouchPage = lazy(() => import("../pages/Retouch"));
const ErrorPage = lazy(() => import("../pages/Errors"));

type IRoute = {
	path: string;
	element: JSX.Element;
	featureFlag?: string;
};

export const useRoutes = () => {
	const routes: IRoute[] = [
		{
			element: (
				<RetouchProvider>
					<RetouchSessionPage />
				</RetouchProvider>
			),
			path: "/retouch",
		},
		{
			element: (
				<RetouchProvider>
					<RetouchPage />
				</RetouchProvider>
			),
			path: "/retouch/:sessionId",
		},
		// {
		//   element: <AreaTransfer />,
		//   path: '/transfer',
		//   privacy: RoutePrivacy.Private,
		//   permissions: [ROLE.CASUAL, ROLE.COUTURE, ROLE.VOGUE, ROLE.ADMIN],
		//   featureFlag: 'area-transfer',
		// },
		{
			element: <StripePricingTable />,
			path: "/subscriptions",
		},

		{
			element: <ErrorPage pageType={Page.Wrong} />,
			path: "/error",
		},
		{
			element: <ErrorPage pageType={Page.PageNotFound} />,
			path: "*",
		},
	];
	return routes;
};
