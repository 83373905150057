import type React from "react";
import { AuthKitProvider } from "@workos-inc/authkit-react";
import { useNavigate } from "react-router-dom";
interface Props {
	children: React.ReactNode;
}

export const WorkosProviderWithNavigate: React.FC<Props> = ({ children }) => {
	const clientId = import.meta.env.VITE_AUTHKIT_CLIENT_ID;
	const navigate = useNavigate();

	return (
		<AuthKitProvider
			devMode={true}
			onRefreshFailure={(args) => {
				args.signIn();
			}}
			clientId={clientId}
			onRedirectCallback={({ state }) => {
				if (state?.returnTo) {
					navigate(state.returnTo);
				}
			}}
		>
			{children}
		</AuthKitProvider>
	);
};
